import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

//Signup Component
function Signup() {

  let navigate = useNavigate();

  //For Registration
  let registerUser = async (e) => {
    
    e.preventDefault();
    let endpoint = process.env.REACT_APP_API_URL + "/api/register/";

    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: e.target.username.value,
        password: e.target.password.value,
        email: e.target.email.value,
        first_name: e.target.first_name.value,
        last_name: e.target.last_name.value,
        schoolname: e.target.schoolname.value,
      }),
    });

    toast.success("Signed up Successfully!!");
    navigate("/");
  };

  return (
    <>
      <section class="vh-100">
        <div class="container py-4 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">

              <form onSubmit={registerUser}>
                <div class="card shadow-2-strong">
                  <div class="card-body p-3 text-center">
                    <h3 class="font-monospace">Sign Up</h3>
                    <hr class="my-4" />

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="text"
                        id="fname"
                        name="first_name"
                        class="form-control form-control-lg"
                        placeholder="First Name"
                        required
                      />
                    </div>

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="text"
                        id="lname"
                        name="last_name"
                        class="form-control form-control-lg"
                        placeholder="Last Name"
                        required
                      />
                    </div>

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="text"
                        id="schoolname"
                        name="schoolname"
                        class="form-control form-control-lg"
                        placeholder="School Name"
                        required
                      />
                    </div>

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        class="form-control form-control-lg"
                        placeholder="Email"
                        required
                      />
                    </div>

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="text"
                        id="username"
                        name="username"
                        class="form-control form-control-lg"
                        placeholder="UserName"
                        required
                      />
                    </div>

                    <div class="form-outline mb-4 font-monospace">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        class="form-control form-control-lg"
                        placeholder="Password"
                        required
                      />
                    </div>

                    <button
                      class="btn btn-primary btn-lg btn-block me-6 font-monospace"
                      type="submit"
                      formmethod="POST"
                    >
                      Sign Up
                    </button>

                    <div class="text-center mt-3">
                     Already have an account?{" "}
                      <button
                        type="submit"
                        class="btn_signin rounded submit"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                      Sign In
                      </button>
                    </div>

                  </div>
                </div>  
              </form>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Signup;
