import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import sport_image from "../sport.jpeg";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import { toast } from 'react-toastify';

let token_decoded = "";

//Login Component
function Login() {

  let navigate = useNavigate();
  const [cookies, setCookies] = useCookies();
  let endpoint = process.env.REACT_APP_API_URL + "/api/token/";


  useEffect(() => {       
   console.log(document.cookie);
   let unm = getCookiedata('username');
   let pwd = getCookiedata('password');

   document.getElementById("uname").value = unm;
   document.getElementById("upassword").value = pwd;
  }, []);


  //ForLogin
  let loginUser = async (e) => {
    e.preventDefault();

    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: e.target.username.value,
        password: e.target.password.value,
      }),
    }).catch((error) => {
      console.log("Error:", error);
    });

    let data = await response.json();
    console.log("Data: ", data);

    token_decoded = jwt_decode(data["access"]);
    console.log(token_decoded);

    let username = token_decoded["username"];
    // console.log(username);
    localStorage.setItem('uname', username);

    let login = 0;
    login = token_decoded["login"];
    console.log(login);

    if (login === 1) {
      setCookies("access_token", data["access"]);
      setCookies("refresh_token", data["refresh"]);
      navigate("/mainpage");
      toast.success("Logged in Successfully!!");
    }
    else{
      toast.error("Invalid Credentials!!");
    }

  };

  
  //RememberMe Code
  let setCookiedata = () => {

    let unmInput = document.getElementById("uname").value;
    let pwdInput = document.getElementById("upassword").value;

    document.cookie = "username= " + unmInput + ";path=http://localhost:3000/";
    document.cookie = "password=" + pwdInput + ";path=http://localhost:3000/"; 

  }

  let getCookiedata = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  // Toggling Password Visibility
  let togglePwd = () => {
    var pwd = document.getElementById("upassword");
    var spn = document.getElementById("toggle-password");
    if (pwd.type === "password" && spn.className === "far fa-eye-slash") {
      pwd.type = "text";
      spn.className = "far fa-eye" 
    } else {
      pwd.type = "password";
      spn.className = "far fa-eye-slash"
    }
  }  


  return (
    <>
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10">
              <div class="wrap d-md-flex">
                <div class="img">
                  <img
                    src={sport_image}
                    height="100%"
                    width="100%"
                    alt="not found"
                  />
                </div>
                <div class="login-wrap p-4 p-md-5">

                  <div class="d-flex">
                    <div class="w-100">
                      <h3 class="mb-4">Sign In</h3>
                    </div>
                  </div>

                  <form onSubmit={loginUser} class="signin-form">

                    <div class="form-group mb-3">
                      <label class="label" for="name">
                        Username
                      </label>
                      <input
                        type="text"
                        id="uname"
                        name="username"
                        class="form-control"
                        placeholder="Username"
                        required
                      />
                    </div>

                    <div class="form-group mb-3">
                      <label class="label" for="password">
                        Password
                      </label>
                      <div>
                      <input
                          type="password"
                          style={{ display: 'inline' }}
                          id="upassword"
                          name="password"
                          class="form-control"
                          placeholder="Password"
                          required
                        />
                        <span
                          className="far fa-eye-slash" id="toggle-password" onClick={togglePwd} 
                          style={{marginLeft: '-25px', marginTop: '13px', position: 'relative', zIndex: '1', cursor: 'pointer'}}>
                        </span>
                      </div>
                      
                    </div>

                    <div class="w-50 text-left">
                      <label class="checkbox-wrap checkbox-primary mb-0">
                        Remember Me
                        <input type="checkbox" id="rememberMe" onClick={setCookiedata} />
                        <span class="checkmark"></span>
                      </label>
                    </div>

                    <br></br>
                    
                    <div class="form-group">
                      <button
                        type="submit"
                        class="form-control btn btn-primary rounded submit px-3"
                      >
                        Sign In
                      </button>
                    </div>

                  </form>
                  <div class="text-center">
                    Not a member?{" "}
                    <button
                      type="submit"
                      class="btn_signup rounded submit"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
export { token_decoded };
