import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import profile_img from "../profile.png";
import logout_img from "../logout.svg";
import home_img from "../home.svg";
import { Cookies, useCookies } from "react-cookie";
import { toast } from 'react-toastify';
import moment from 'moment';

//History Component
const History = () => {

  const [cookies, removeCookies] = useCookies();
  const [udata, setUdata] = useState([]);
  const [unm,setUnm] = useState();
  let navigate = useNavigate();

  //Fetching userdata
  useEffect(() => {

    let endpoint = process.env.REACT_APP_API_URL + "/api/user/info";
    const token = "Bearer " + cookies.access_token;
    axios
      .get(endpoint, {headers: {'Authorization': token}}) //API called
      .then((res) => {
        console.log(res);
        setUdata(res.data)
      })
      .catch((err) => {
        console.log(err);
      });

    const usrnm = localStorage.getItem('uname');
    setUnm(usrnm);

  }, []);


  return (
    <>
      <div>
        <nav
          className="navbar navbar-expand-sm navbar-light bg-light mt-2"
        >
          <div className="container-fluid">
            <a
              className="navbar-brand "
              style={{ color: '#8982B5' ,fontSize:'35px' }}
              href="#"
            >
              Skipiks.com
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbarCollapse" className="collapse navbar-collapse">
                <ul className="nav navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><img
                            src={profile_img}
                            height="30px"
                            width="30px"
                            alt="not found"
                          />&nbsp; {unm}</a>

                        <div className="dropdown-menu dropdown-menu-end">
                            <a href="#" className="dropdown-item" onClick={()=> {navigate("/mainpage")}}><img
                              src={home_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; MainPage</a>

                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item"  
                             onClick={() => {
                            removeCookies("access_token");
                            removeCookies("refresh_token");
                            localStorage.clear();
                            toast.success("Logged Out!!");
                            navigate("/");
                             }}><img
                              src={logout_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
   
          </div>
        </nav>
      </div>
      <div className="container text-center">
        <div className="row">
          <div className="col-md-12">
            <h3 style={{ backgroundColor: '#9088CC', borderColor: '#bee5eb', color: 'white' }}>History Details</h3>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Jumping Date/Time</th>
                <th scope="col">SimpleJumps</th>
                <th scope="col">CrossJumps</th>
                <th scope="col">TotalJumps</th>
              </tr>
            </thead>
            <tbody>
              {udata?.map((ud,key) => {
                let x = moment(ud.created_at).format("YYYY-MM-DD hh:mm:ss");
                return(
                <tr key={key}>
                  <td>{x}</td>
                  <td>{ud.simpleJumps}</td>
                  <td>{ud.crossJumps}</td>
                  <td>{ud.totalJumps}</td>
                </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
}

export default History;