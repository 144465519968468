 import * as posedetection from '@tensorflow-models/pose-detection';
 
 export const DEFAULT_LINE_WIDTH = 2;
 export const DEFAULT_RADIUS = 4;
 
 export const VIDEO_SIZE = {
   '640 X 480': {width: 640, height: 480},
   '640 X 360': {width: 640, height: 360},
   '360 X 270': {width: 360, height: 270}
 };
 export const STATE = {
   camera: {targetFPS: 60, sizeOption: '640 X 480'},
   backend: '',
   flags: {},
   modelConfig: {}
 };
 export const BLAZEPOSE_CONFIG = {
   maxPoses: 1,
   type: 'full',
   scoreThreshold: 0.65,
   render3D: true
 };