import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import profile_img from "../profile.png";
import bg_img from "../bgimg.jpg";
import history_img from "../history.svg";
import download_img from "../download.svg";
import upload_img from "../upload.svg";
import logout_img from "../logout.svg";
import start_record from "../START.svg";
import stop_record from "../STOP.svg";
import { token_decoded } from "./login";
import { Cookies, useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import '@tensorflow/tfjs-backend-webgl';
import * as poseDetection from '@tensorflow-models/pose-detection';
import { toast } from 'react-toastify';
import * as params from './params';


params.STATE.model = poseDetection.SupportedModels.BlazePose;

//Mainpage Component
function Mainpage() {

  const detectorConfig = {
    runtime: 'tfjs',
    enableSmoothing: true,
    modelType: 'full'
  };

  var jumping_sessionid = null; 
  let recording = null;
  let detector = null;
  let mediaRecorderRef = null;

  let navigate = useNavigate();
  const webcamRef = useRef(null);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [cookies, removeCookies] = useCookies();
  const [totalJumps, settotalJumps] = useState(0);
  const [simpleJumps, setsimpleJumps] = useState(0);
  const [crossJumps, setcrossJumps] = useState(0);
  const [intval, setIntval] = useState();
  const [unm, setUnm] = useState();
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  let username = token_decoded["username"];
  let user_id = token_decoded["user_id"];
  let supported_media_type = "video/webm";

  const COLOR_PALETTE = [
    '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
    '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
    '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9'
  ];


  //Upload_Userdata Code
  const uploadUserData = async () => {
    const token = "Bearer " + cookies.access_token;
    let endpoint = process.env.REACT_APP_API_URL + "/api/user/info";
    jumping_sessionid = uuidv4();
    
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        // userid: user_id,
        totalJumps: totalJumps,
        simpleJumps: simpleJumps,
        crossJumps: crossJumps,
        jumping_sessionid: jumping_sessionid,
      }),
    });

    let data = await response.json();
    console.log("User Data: ", data);
  };


  //Upload_Uservideo Code
  const uploadUserVideo = async () => {

    const token = "Bearer " + cookies.access_token;
    console.log("---", token);
    let endpoint = process.env.REACT_APP_API_URL + "/api/user/video/upload";
    let blob;

    if(/Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)){
      supported_media_type = 'video/mp4'
    }
    if (recordedChunks?.length) {

      blob = new Blob(recordedChunks, { type: supported_media_type });
      const myFile = new File([blob], "video.mp4", {type: 'video/mp4'});
      console.log(myFile);

      var vdata = new FormData();
      vdata.append('video', myFile);
      // console.log(vdata.get('video'));

      var config = {
        method: 'post',
        url: endpoint,
        headers: { 
          'Authorization': token
        },
        data : vdata
      };

      axios(config)
      .then(function (response) {
        console.log("User video: ", response.data);
      })
      toast.success("Video Uploaded!!");
    }
    else{
      toast.info("Record Video!!");
    }
    console.log(recordedChunks);
  };


  // Video-Recording code
  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);

    const video = document.querySelector("#video-container > div.video-frame > video"); 
    const stream = video.srcObject;

    if(/Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)){
      supported_media_type = 'video/mp4'
    }

    console.log('Supported media type---', stream);

    mediaRecorderRef = new MediaRecorder(stream, {
      mimeType: supported_media_type
    });

    mediaRecorderRef.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.start(100);
  }, [webcamRef, setCapturing, mediaRecorderRef]);


  // Data availability code
  const handleDataAvailable = useCallback(
    ({ data }) => {
      // console.log("-------------------", data)
      
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [recordedChunks]
  );


  // Stop-recording code
  const handleStopCaptureClick = useCallback(() => {
    console.log(mediaRecorderRef.state);
    mediaRecorderRef?.stop();
    console.log("stopped");
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);  


  // Video-downloading code
  const handleDownload = useCallback(() => {

    console.log(recordedChunks);

    if(/Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)){
      supported_media_type = 'video/mp4'
    }

    if (recordedChunks?.length) {
      var blob = new Blob(recordedChunks, { type: supported_media_type });
      console.log(blob);

      const url = URL.createObjectURL(blob);
      console.log(url)

      const a = document.getElementById("anchor-tag");
      console.log(a);
      a.style = "display: inline";
      a.href = url;
      a.download = "test-file";
      toast.success("Video Downloaded!!");
    }
    else{
      toast.info("Record Video!!");
    }
  }, [recordedChunks]);


  //Declared Variables for ML part
  const canvasElement = document.getElementsByClassName('output_canvas')[0];
  let canvasCtx = useRef(null);
  let counter = 0;
  let cross_counter = 0;
  let stage = null;
  let j = 0;
  let h_nose = 0;
  let h_left_knee = 0;
  let h_right_knee = 0;
  let mn_nose = Number.POSITIVE_INFINITY;
  let mx_nose = Number.NEGATIVE_INFINITY;
  let mn_left_knee = Number.POSITIVE_INFINITY;
  let mx_left_knee = Number.NEGATIVE_INFINITY;
  let mn_right_knee = Number.POSITIVE_INFINITY;
  let mx_right_knee = Number.NEGATIVE_INFINITY;
  let first_nose_v = 0;
  let cross_jump = true;
  let nose_value;
  let left_knee_value;
  let right_knee_value;
  let leg_height_diff;
  let leg_height_sum;
  let cross_diff;
  let minmax;
  let diff_nose;
  let hdf_nose;
  let diff_left;
  let leg_landmarks = null;
  let hdf_left_leg;
  let diff_right;
  let hdf_right_leg;
  let per_change_nose;
  let per_change_left;
  let per_change_right;
  let leg_height_percentage;
  var video;
  var canvas;

  //Mounting code:
  useEffect(() => {
    canvas = document.getElementById('output_canvas');
    canvas.style.width = '100%';
    canvas.style.height = '100%';

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.height;

    var ctx = canvas.getContext("2d");
    let img = new Image();
    img.src = require('../bgimg.jpg');
    setTimeout(function () {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }, 800);

    const usrnm = localStorage.getItem('uname');
    console.log(usrnm);
    setUnm(usrnm);

    console.log('---detector--', detector);

    // if(!MediaRecorder.isTypeSupported('video/webm')) {
    //   supported_media_type = "video/mp4";
    // }
  }, [detector]);


  const drawCtx = (video) => {
    canvasCtx.drawImage(video, 0, 0, video.width, video.height);
  }
  
  const clearCtx = (video) => {
    canvasCtx.clearRect(0, 0, video.width, video.height);
  }
  
  const drawResults = (poses) => {
    for (const pose of poses) {
      drawResult(pose);
    }
  }
  
  let drawResult = (pose) =>{
    if (pose.keypoints != null) {
        drawKeypoints(pose.keypoints);
        drawSkeleton(pose.keypoints, pose.id);
    }
  }

  const drawKeypoints = (keypoints) => {
    const keypointInd =
        poseDetection.util.getKeypointIndexBySide(params.STATE.model);
        canvasCtx.fillStyle = 'Red';
        canvasCtx.strokeStyle = 'White';
        canvasCtx.lineWidth = params.DEFAULT_LINE_WIDTH;
    
    for (const i of keypointInd.middle) {
      drawKeypoint(keypoints[i]);
    }  
    canvasCtx.fillStyle = 'Green';
    for (const i of keypointInd.left) {
      drawKeypoint(keypoints[i]);
    }
    canvasCtx.fillStyle = 'Orange';
    for (const i of keypointInd.right) {
      drawKeypoint(keypoints[i]);
    }
  }

  const drawKeypoint = (keypoint) =>{ 
    const score = keypoint.score != null ? keypoint.score : 1;
    const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
  
    if (score >= scoreThreshold) {
      const circle = new Path2D();
      circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
      canvasCtx.fill(circle);
      canvasCtx.stroke(circle);
    }
  }

  const drawSkeleton = (keypoints, poseId) => {
    // Each poseId is mapped to a color in the color palette.
    const color = params.STATE.modelConfig.enableTracking && poseId != null ?
        COLOR_PALETTE[poseId % 20] :
        'White';
    canvasCtx.fillStyle = color;
    canvasCtx.strokeStyle = color;
    canvasCtx.lineWidth = params.DEFAULT_LINE_WIDTH;
    
    poseDetection.util.getAdjacentPairs(params.STATE.model).forEach(([i, j]) => {
      const kp1 = keypoints[i];
      const kp2 = keypoints[j];
          // If score is null, just show the keypoint.
      const score1 = kp1.score != null ? kp1.score : 1;
      const score2 = kp2.score != null ? kp2.score : 1;
      const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
    
      if (score1 >= scoreThreshold && score2 >= scoreThreshold) {
        canvasCtx.beginPath();
        canvasCtx.moveTo(kp1.x, kp1.y);
        canvasCtx.lineTo(kp2.x, kp2.y);
        canvasCtx.stroke();
      }
    });
  }


  // useEffect(() => console.log('---detector--', detector), [detector]);


  // Pose-Detection Code
  const detectWebcamFeed = async () => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      detector != null
    ) {

      // Get Video Properties
      video = document.getElementById('video');
      // console.log(video); 

      let videoContainer = document.getElementById('video-container');
      
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;
      
      canvas = document.getElementById('output_canvas');

      // Must set below two lines, otherwise video element doesn't show.
      video.width = canvas.width;
      video.height = canvas.height;
    
      canvasCtx = canvasElement.getContext('2d');


     //Detect poses 
     const poses = await detector.estimatePoses(video);
     clearCtx(video);
     drawCtx(video);
     if(poses && poses.length > 0) {
        drawResults(poses);
      }
      
      let keypoints_value = poses[0].keypoints;
      const canvasContainer = document.querySelector('.canvas-wrapper');

      let nose_x=poses[0]['keypoints'][0].x / video.width
      // console.log(nose_x)
      let nose_y=poses[0]['keypoints'][0].y / video.height
      // console.log(nose_y)

      let left_ankle_y=poses[0]['keypoints'][27].y / video.width
      let right_ankle_y=poses[0]['keypoints'][28].y / video.width
      let left_knee_y=poses[0]['keypoints'][25].y / video.width
      let right_knee_y=poses[0]['keypoints'][26].y / video.width
      let left_pinky_x=poses[0]['keypoints'][17].x / video.width
      let right_pinky_x=poses[0]['keypoints'][18].x / video.width
      

      let height_nose = left_ankle_y - nose_y;
      let left_leg_height = left_ankle_y - left_knee_y;
      let right_leg_height = left_ankle_y - right_knee_y;

      if (j === 0) {
            leg_landmarks = left_ankle_y;
            j = 1;
          }
      
      nose_value = nose_y;
      left_knee_value = left_knee_y;
      right_knee_value = right_knee_y;

      leg_height_diff = Math.abs(left_leg_height * right_leg_height);
      leg_height_sum = left_leg_height + right_leg_height;
      leg_height_percentage = (leg_height_diff / leg_height_sum) * 100;

      if (h_nose === 0) {
        h_nose = height_nose;
      }
      
      if (h_left_knee === 0) {
        h_left_knee = left_leg_height;
      }
      
      if (h_right_knee === 0) {
        h_right_knee = right_leg_height;
      }
      
      if (first_nose_v === 0) {
        first_nose_v = nose_value;
      }
    
      cross_diff = left_pinky_x - right_pinky_x;
      minmax = first_nose_v / nose_value; 

      if (left_knee_value < 1.0 && right_knee_value < 1.0 && nose_value < 1.0) {
        //console.log("minmax2:", minmax);
        if (minmax > 0.97) {
          //console.log("minmax3")
          if (mn_nose > nose_value) mn_nose = nose_value;
          if (mx_nose < nose_value) mx_nose = nose_value;
          if (mn_left_knee > left_knee_value) mn_left_knee = left_knee_value;
          if (mx_left_knee < left_knee_value) mx_left_knee = left_knee_value;
          if (mn_right_knee > right_knee_value) mn_right_knee = right_knee_value;
          if (mx_right_knee < right_knee_value) mx_right_knee = right_knee_value;
      
          stage = "Up";
          } else if (minmax < 0.97 && stage === "Up") {
          diff_nose = mx_nose - mn_nose;
          hdf_nose = h_nose - diff_nose;
      
          diff_left = mx_left_knee - mn_left_knee;
          hdf_left_leg = h_left_knee - diff_left;
      
          diff_right = mx_right_knee - mn_right_knee;
          hdf_right_leg = h_right_knee - diff_right;
      
          per_change_nose = (hdf_nose * 100) / h_nose;
          per_change_left = (diff_left * 100) / h_left_knee;
          per_change_right = (diff_right * 100) / h_right_knee;
      
          if (per_change_nose < 96.0) {
            if (cross_diff < 0.05 && cross_jump) {
              cross_jump = false;
              cross_counter += 1;
              setcrossJumps(cross_counter);
              console.log("Cross Counter", cross_counter);
            } else {
                counter += 1;
                setsimpleJumps(counter);
                console.log(counter);
              }
          }
          settotalJumps(cross_counter + counter);
          mn_nose = Number.POSITIVE_INFINITY;
          mx_nose = Number.NEGATIVE_INFINITY;
          mn_left_knee = Number.POSITIVE_INFINITY;
          mx_left_knee = Number.NEGATIVE_INFINITY;
          mn_right_knee = Number.POSITIVE_INFINITY;
          mx_right_knee = Number.NEGATIVE_INFINITY;
          cross_jump = true;
          stage = "Down";
          }
        }
    }
  };

  //Runposenet code
  const runPosenet = async() => {
    console.log("runposenet runs");
    setIsShowVideo(true); 

    const video = document.querySelector("#video-container > div.video-frame > video"); 
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({
            video: true
        }).then(function(stream) {
            video.srcObject = stream;
            video.play();
        });
    }

    if(detector == null){
      detector = await poseDetection.createDetector(poseDetection.SupportedModels.BlazePose, detectorConfig);
    }

    let interval = setInterval(() => {
      detectWebcamFeed();
    },100);
    setIntval(interval);
    console.log("--------->", intval);
  };


  //Offcamera Code
  let offCamera = () => {
    clearInterval(intval);
    setTimeout(stopCamera, 900);
  }


  //Stopcamera Code
  let stopCamera = () => {
    console.log("stop camera runs --->", intval);
    setIsShowVideo(false);
    
    const video = document.querySelector("#video-container > div.video-frame > video"); 
    const stream = video.srcObject;
    if (stream) {
      const tracks = stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });

      video.srcObject = null;
    }

    uploadUserData(); //API called
    setsimpleJumps(0);
    setcrossJumps(0);
    settotalJumps(0);
    counter = 0;
    cross_counter = 0;

    canvas = document.getElementById('output_canvas');
    canvas.style.width = '100%';
    canvas.style.height = '100%';

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.height;

    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    let img = new Image();
    img.src = require('../bgimg.jpg');
    setTimeout(function () {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    }, 100);
    
  };


  return (
    <>
      <div>
        <nav
          className="navbar navbar-expand-sm navbar-light bg-light mt-2"
        >
          <div className="container-fluid">
            <a
              className="navbar-brand "
              style={{ color: '#8982B5' ,fontSize:'35px' }}
              href="#"
            >
              Skipiks.com
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbarCollapse" className="collapse navbar-collapse">
                <ul className="nav navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><img
                            src={profile_img}
                            height="30px"
                            width="30px"
                            alt="not found"
                          />&nbsp; {unm}</a>

                        <div className="dropdown-menu dropdown-menu-end">
                            <a href="#" className="dropdown-item" onClick={()=> {navigate("/history")}}><img
                              src={history_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; History</a>

                            <a href="#" className="dropdown-item" onClick={uploadUserVideo}><img
                              src={upload_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; Upload Recording</a>

                            <a href="" id="anchor-tag" className="dropdown-item" onClick={handleDownload} ><img
                              src={download_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; Download Recording</a>

                            <a href="#" className="dropdown-item"  
                             onClick={() => {
                            removeCookies("access_token");
                            removeCookies("refresh_token");
                            localStorage.clear();
                            toast.success("Logged Out!!");
                            navigate("/");
                             }}><img
                              src={logout_img}
                              height="20px"
                              width="20px"
                              alt="not found"
                            />&nbsp; Logout</a>

                        </div>
                    </li>
                </ul>
            </div>
          </div>
        </nav>
      </div>
      <hr />
      <div>
        <br />
        <div className="float-right" style={{ marginRight: "115px" }}
         >
          <label className="switch">
            <input type="checkbox" id="togBtn" />
              <div id="start-camera" className="slider round" onClick={isShowVideo ? offCamera: runPosenet}>
              <span
              className="off"
              style={{ backgroundColor: "9F7FB3" }}
              >
                Camera OFF
              </span>
              <span className="on">
                Camera ON
              </span>
              </div>
          </label>
        </div>
        <div className="float-right mr-5"></div>
      </div>
      <br />
      <br />

      <div className="container-wrapper">
        <div className="row ms-0 me-0">
          <div className="col-md-3 col-sm-12 card-container">
            <div className="text-center main-card first">
              <div className="card-content">
                <h4 className="card-title" style={{ fontFamily: "sans-serif" }}>
                  Total Jumps
                </h4>
                <h2
                  className="card-subtext"
                  style={{ fontFamily: "sans-serif" }}
                >
                  {totalJumps}
                </h2>
              </div>
            </div>
            <div className="text-center main-card second">
              <div className="card-content">
                <h4 className="card-title" style={{ fontFamily: "sans-serif" }}>
                  Simple Jumps
                </h4>
                <h2
                  className="card-subtext"
                  style={{ fontFamily: "sans-serif" }}
                >
                  {simpleJumps}
                </h2>
              </div>
            </div>
            <div className="text-center main-card third">
              <div className="card-content">
                <h4 className="card-title" style={{ fontFamily: "sans-serif" }}>
                  Cross Jumps
                </h4>
                <h2
                  className="card-subtext"
                  style={{ fontFamily: "sans-serif" }}
                >
                  {crossJumps}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-12 video-container" id="video-container">
            <div className="video-frame">

              <canvas
               ref={canvasCtx}
               className="output_canvas" 
               width="100%" height="720px"
               id="output_canvas"
               style={{
                //position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                left: 0,
                right: 0,
                textAlign: "center",
                zindex: 9,
                // width: 640,
                // height: 480
              }}
               />

              <video 
                preload="none"
                poster={bg_img}
                ref={webcamRef}
                id="video"
                className="video5"
                style={{
                  objectFit: "fill",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  margin: 0
                }}
              >
              </video>
              
            </div>
            <div className="video-buttons">
              <div className="d-flex justify-content-center controls">

              {capturing ? (
                   <button
                    className="btn pt-0"
                    id="pause-btn"
                    onClick={handleStopCaptureClick}> 
                    <img src={stop_record} alt="" width="32" height="32" />
                    </button>
                      ) : (
                   <button 
                    className="btn pt-0"
                    id="record-btn"
                    onClick={handleStartCaptureClick}>
                    <img src={start_record} alt="" width="32" height="32" />
                  </button>
              )}
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  );
}

export default Mainpage;